import { Navigate, Route, Routes } from "react-router";
import "./App.css";
import RegisterForm from "./pages/registerFormPage/RegisterForm";
import StatusPage from "./pages/statusPage/StatusPage";
import Login from "./pages/loginPage/Login";
import Update from "./pages/updatePage/Update";
import { useEffect, useState } from "react";
import ProtectedRoute from "./routes/ProtectedRoutes";
import Navbar from "./components/navbar/Nabvar";
import Sidebar from "./components/sidebar/Sidebar";
import AllDevices from "./pages/AllDevicesPage/AllDevices";
import Home from "./pages/HomePage/Home";
import DeviceDetails from "./pages/deviceDetailsPage/DeviceDetails";

function App() {
  const [authenticated, setAuthenticated] = useState(

    localStorage.getItem("authenticated") === "true" // Check localStorage for authentication

  );
  useEffect(() => {
    localStorage.setItem('authenticated',authenticated);
  },[authenticated])
  const [activeIndex,setActiveIndex] = useState(1);
  return (
    <div className="App">
      <div className="main-content">
        <div className="body-section">
          <div className="body-inner">
            <Navbar />
            {authenticated && <div className="sidebar-section min-vh-0">
              <Sidebar activeIndex={activeIndex} setActiveIndex={setActiveIndex}/> 
            </div>}

            <Routes>
              <Route path="/register/:id" element={<RegisterForm />} />
              <Route path="/status/:id" element={<StatusPage />} />
              <Route path="/all-devices" element={<AllDevices />} />
              <Route path="/update/:id/:objId" element={<Update />} />
              <Route path="device-details/:objId/:empId" element={<DeviceDetails/>} />
              <Route
                path="/login"
                element={<Login setAuthenticated={setAuthenticated} />}
              />
              <Route path="/" element={<Home/>} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
