import {faTabletScreenButton,faLaptop,faMobileScreen} from '@fortawesome/free-solid-svg-icons';
const icons =[ 
    {
        value:1,
        label:"Laptop",
        icon: faLaptop,
    },
    {
        value:2,
        label:"Tablet",
        icon: faTabletScreenButton,
    },
    {
        value:3,
        label:"Mobile",
        icon: faMobileScreen,
    }
]

export default icons;