// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* popup.css */
.popup { 
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 300px;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .popup.success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #3c763d;
  }
  
  .popup.error {
    background-color: #102baa;
    color: #dee4ff;
    font-weight: bold;
    border-radius: 20px;
}
  
  .popup button {
    margin-top: 10px;
    padding: 10px 25px;
    cursor: pointer;
    background: #b3bdef;
    border: 0px;
    border-radius: 5px;
    color: #102baa;
}

.popup button:hover {
  background: #dee4ff;
}
  `, "",{"version":3,"sources":["webpack://./src/styles/popup/popup.css"],"names":[],"mappings":"AAAA,cAAc;AACd;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gBAAgB;IAChB,gCAAgC;IAChC,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,uCAAuC;IACvC,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,mBAAmB;AACvB;;EAEE;IACE,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,WAAW;IACX,kBAAkB;IAClB,cAAc;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":["/* popup.css */\n.popup { \n    position: fixed;\n    top: 50%;\n    left: 50%;\n    min-width: 300px;\n    transform: translate(-50%, -50%);\n    padding: 20px;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    text-align: center;\n  }\n  \n  .popup.success {\n    background-color: #dff0d8;\n    border-color: #d6e9c6;\n    color: #3c763d;\n  }\n  \n  .popup.error {\n    background-color: #102baa;\n    color: #dee4ff;\n    font-weight: bold;\n    border-radius: 20px;\n}\n  \n  .popup button {\n    margin-top: 10px;\n    padding: 10px 25px;\n    cursor: pointer;\n    background: #b3bdef;\n    border: 0px;\n    border-radius: 5px;\n    color: #102baa;\n}\n\n.popup button:hover {\n  background: #dee4ff;\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
