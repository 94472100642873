import axios from 'axios';
import baseUrl from '../config/config';

const api = axios.create({
  baseURL: baseUrl, // Replace with your API URL
  withCredentials: false, // Ensure cookies are sent with requests if needed
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403) {
      // Token expired or not authorized
      localStorage.removeItem('token'); // Clear the token
      localStorage.removeItem('authenticated');
      localStorage.removeItem('user_id');
      alert("Session timeout. Please login again....")
      window.close() // close the page when token  expiers
    }
    return Promise.reject(error);
  }
);

export default api;
