// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App{
  font-family: "Poppins";
  height: 100%;
}
.text-center{
  text-align: center;
  color: rgb(11, 11, 92);
}
.white{
  color: white;
}
.main-content{
  height: 100%; 
  display: flex;
  flex-direction:column;
}
.heading-text{
  width: 90%;
  color: #676767;
  margin-bottom: 20px;
}

.heading-text{
  width: 90%;
  color: #676767;
  margin-bottom: 20px; 
}
.body-section {
  display: flex;
  margin-top: 78px;
  height: 100%;
}
.body-inner{
  display: flex;
  width: 100%;
}

*, ::after, ::before {
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,aAAa;EACb,qBAAqB;AACvB;AACA;EACE,UAAU;EACV,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".App{\n  font-family: \"Poppins\";\n  height: 100%;\n}\n.text-center{\n  text-align: center;\n  color: rgb(11, 11, 92);\n}\n.white{\n  color: white;\n}\n.main-content{\n  height: 100%; \n  display: flex;\n  flex-direction:column;\n}\n.heading-text{\n  width: 90%;\n  color: #676767;\n  margin-bottom: 20px;\n}\n\n.heading-text{\n  width: 90%;\n  color: #676767;\n  margin-bottom: 20px; \n}\n.body-section {\n  display: flex;\n  margin-top: 78px;\n  height: 100%;\n}\n.body-inner{\n  display: flex;\n  width: 100%;\n}\n\n*, ::after, ::before {\n  box-sizing: border-box;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
