import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../styles/statuspage/status.css'; // Import your CSS file for styling
import baseUrl from '../../config/config';
import { BsInfoCircle } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import Navbar from '../../components/navbar/Nabvar';
import { Form, Table } from 'react-bootstrap';
import "../../styles/table/table.css"
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FaPlus } from "react-icons/fa";
import api from '../../api/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icons from '../../type/type';    
const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];


const AllDevices = () => {
    const navigate = useNavigate()
    const token = localStorage.getItem('token'); //retrive the token from the local storage
    const [details, setDetails] = useState([]);
    const [filterdDetails, setFilterdDetails] = useState([])
    const [hoveredRow, setHoveredRow] = useState(null);
    const [search, setSearch] = useState("")
    const [statusFilter, setStatusFilter] = useState(() => {
        const savedStatus = localStorage.getItem('status');
        return savedStatus ? savedStatus : "Active"; 
    })
    const [changed, setChanged] = useState(false);
    const [rowCount, setRowCount] = useState();
    const [currentPage, setCurrentPage] = useState(() => {
        const savedPage = localStorage.getItem('currentPage');
        return savedPage ? parseInt(savedPage, 10) : 1; // Default to page 1 if not found
    }); // Current page number
    const itemsPerPage = 5;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const id = localStorage.getItem("user_id");
    const isAdmin = localStorage.getItem("access_type");
    const displayedOrders = filterdDetails?.slice(indexOfFirstItem, indexOfLastItem);    
    const [load, setLoad] = useState(true);  

    const fetchDetails = async () => {
        try {
            if (!token) {
                navigate("/login")
            } else {
                const response = await api.get(`${baseUrl}/api/all-device-details`, {
                    headers: {
                        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                    },
                });
                setDetails(response.data);
                setRowCount(response.data.length)
                setTimeout(() => {
                    setLoad(false);
                }, 2000);
            }

        } catch (error) {
            console.error('Error fetching details:', error);
        }
    };

    useEffect(() => {
        setLoad(true);
        fetchDetails();
    }, [id]);

    const getIconForDeviceType = (deviceType) => {
        const matchedIcon = icons.find(icon => icon.value === deviceType);
        return matchedIcon ? matchedIcon.icon : null;
    };

    const handleMouseEnter = (index) => {
        setHoveredRow(index);
    };

    const handleMouseLeave = () => {
        setHoveredRow(null);
    };

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
        localStorage.setItem('currentPage', newPage);
    }

    const handleViewFunction = (objectID, emp_id) =>{
        navigate(`/device-details/${objectID}/${emp_id}`)
    }

    const handleDelete = async (objId) => {
        // Show a confirmation dialog
        const userConfirmed = window.confirm('Are you sure you want to delete this device entry?');

        if (userConfirmed) {
            try {
                if (!token) {
                    navigate("/login")
                } else {
                    await api.delete(`${baseUrl}/api/device-delete/${objId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                        },
                    });
                    fetchDetails();
                }
            } catch (error) {
                console.error('Error deleting entry:', error);
                // setDeleteStatus('Error deleting entry');
            }
        } else {
            // setDeleteStatus('Deletion canceled');
        }
    };

    const handleUpdate = (objId, employee_id) => {
        navigate(`/update/${employee_id}/${objId}`)
    }

    const BackToHome = () => {
        navigate("/")
    }

    const registerDevice = () => {
        navigate(`/register/${id}`)
    }

    useEffect(() => {
        localStorage.setItem('status', statusFilter);
        if (details) {
            const updatedDevices = details.map(device => {
              const dueDate = new Date(device.dueDate);
              const today = new Date();
              
              let statusIndicator = '';
          
              if (dueDate) {
                const oneDayBeforeDueDate = new Date(dueDate);
                oneDayBeforeDueDate.setDate(dueDate.getDate() - 1);
          
                if (today >= oneDayBeforeDueDate && device.status === 'Active') {
                  // Show 'Expiring soon' if today is >= one day before due date and status is 'Active'
                  statusIndicator = 'Expiring soon';
                } else if (device.status === 'InActive' || device.status === 'Reject') {
                  // Show 'Expired' if dueDate has a value and status is 'Inactive' or 'Rejected'
                  statusIndicator = dueDate ? 'Expired' : '';
                }
              }
            
              return {
                ...device,
                statusIndicator: statusIndicator,
              };
            });

            if (search) {
                const result = updatedDevices.filter((device) => {
                  const searchLower = search.toLowerCase();
                  return (
                    device.deviceName.toLowerCase().includes(searchLower) ||
                    device.macAddress.toLowerCase().includes(searchLower) ||
                    device.employeeId.toString().includes(search)
                  );
                });
              
                setFilterdDetails(result);
                setRowCount(result.length);
            } else if (statusFilter) {
                const result = updatedDevices.filter((device) => {
                    if (statusFilter === 'All') {
                        return device.status.toLowerCase();
                    } else if (statusFilter === 'Active') {
                        return device.status === 'Active';
                    } else {
                        const searchLower = statusFilter.toLowerCase();
                        return device.status.toLowerCase().includes(searchLower);
                    }
                });
            
                setFilterdDetails(result);
                setRowCount(result.length);
            }else {
                setFilterdDetails(updatedDevices);
                setRowCount(updatedDevices.length);
            }
        }
    }, [details, search, statusFilter]);
      

    return (
        <div className='content-section'>
            <div className="content-main-head-section">
                <div className='title-part'>
                    <div className="table-heading">
                        <h2>All Devices</h2>
                    </div>
                    <div className="device-search">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            className="search-icon"
                        >
                            <path d="M10 2a8 8 0 105.29 14.29l4.72 4.71a1 1 0 001.42-1.42l-4.71-4.72A8 8 0 0010 2zm0 2a6 6 0 110 12A6 6 0 0110 4z" />
                        </svg>
                        <input
                            type="text"
                            placeholder="Search..."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                <div className='device-filter'>
                    <select name="status" id="status" onChange={(e) => setStatusFilter(e.target.value)} defaultValue={statusFilter}>
                    <option value="Active">Active</option>
                    <option value="All">All</option>
                    <option value="Pending">Pending</option>
                    <option value="InActive">Inactive</option>
                    <option value="Reject">Reject</option>
                    </select>
                </div>

            </div>
            {console.log('details.length',displayedOrders.length)}
            {load && load === true?
                
                <div className='loading-sec-main'>
                <div className='d-flex flex-column align-items-center'>
                    <div className='loading-image'><img  src="/images/tube-spinner.svg" alt="Profile Pic" className='loading-image-item'/></div>
                    <div className='loading-text'>Loading</div>
                </div>
                </div>
            :
                
            displayedOrders.length > 0 ? (

                    <div className="details-table">

                        <Table striped bordered hover className='custom-table'>
                            <thead className="custom-thead">
                                <tr className='custom-tr'>
                                    <th className='name-hd'> DEVICE NAME</th>
                                    <th>EMPLOYEE ID</th>
                                    <th>DEVICE MODEL</th>
                                    <th>OPERATING SYSTEM</th>
                                    <th>MAC ADDRESS</th>
                                    <th>STATUS</th>
                                    <th>APPROVAL DATE</th>
                                    <th>DUE DATE</th>
                                    <th className='action-head'>ACTIONS</th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                {displayedOrders.map((item, index) => (
                                    <tr key={index}>
                                        <td
                                            width="15%"
                                            style={{
                                                borderBottom:
                                                     item.statusIndicator === 'Expiring soon'
                                                        ? '1px solid #c02222'
                                                        : 'none',
                                            }}
                                        >
                                            <div className="name-td">
                                                <div className="device-type">
                                                    <FontAwesomeIcon
                                                        className="font-icons"
                                                        icon={getIconForDeviceType(item.deviceType)}
                                                    />
                                                </div>
                                                <div className="name-td-txt">{item.deviceName}</div>
                                            </div>
                                        </td>
                                        <td
                                            width="10%"
                                            style={{
                                                borderBottom:
                                                     item.statusIndicator === 'Expiring soon'
                                                        ? '1px solid #c02222'
                                                        : 'none',
                                            }}
                                        >
                                            {item.employeeId}
                                        </td>
                                        <td
                                            width="10%"
                                            style={{
                                                borderBottom:
                                                     item.statusIndicator === 'Expiring soon'
                                                        ? '1px solid #c02222'
                                                        : 'none',
                                            }}
                                        >
                                            {item.deviceModel}
                                        </td>
                                        <td
                                            width="10%"
                                            style={{
                                                borderBottom:
                                                     item.statusIndicator === 'Expiring soon'
                                                        ? '1px solid #c02222'
                                                        : 'none',
                                            }}
                                        >
                                            {item.operatingSystem}
                                        </td>
                                        <td
                                            width="13%"
                                            style={{
                                                borderBottom:
                                                     item.statusIndicator === 'Expiring soon'
                                                        ? '1px solid #c02222'
                                                        : 'none',
                                            }}
                                        >
                                            {item.macAddress}
                                        </td>
                                        <td
                                            width="10%"
                                            style={{
                                                borderBottom:
                                                     item.statusIndicator === 'Expiring soon'
                                                        ? '1px solid #c02222'
                                                        : 'none',
                                            }}
                                        >
                                            <div
                                                className="active_container"
                                                style={{
                                                    color: `${item.status === 'Active' ? '#008000' : item.status === 'InActive' ? '#102baa' : item.status === 'Pending' ? '#eea121' : '#FF0000'}`,
                                                    backgroundColor: `${item.status === 'Active' ? '#e9faf1' : item.status === 'InActive' ? '#d7deff' : item.status === 'Pending' ? '#fcf3d7' : '#fee8e7'}`,
                                                }}
                                            >
                                                {item.status}
                                            </div>
                                        </td>
                                        <td
                                            width="10%"
                                            style={{
                                                borderBottom:
                                                     item.statusIndicator === 'Expiring soon'
                                                        ? '1px solid #c02222'
                                                        : 'none',
                                            }}
                                        >
                                            <div className="date-section">
                                                {item.approvalDate ? (
                                                    <>
                                                        <span>
                                                            {month[new Date(item.approvalDate).getMonth()] +
                                                                ' ' +
                                                                new Date(item.approvalDate).getDate() +
                                                                ', ' +
                                                                new Date(item.approvalDate).getFullYear()}
                                                        </span>
                                                        <span>
                                                            {new Date(item.approvalDate).toLocaleTimeString()}
                                                        </span>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </td>
                                        <td
                                            style={{
                                                borderBottom:
                                                     item.statusIndicator === 'Expiring soon'
                                                        ? '1px solid #c02222'
                                                        : 'none',
                                            }}
                                        >
                                            <div className="date-section">
                                                {item.dueDate ? (
                                                    <>
                                                        <span>
                                                            {month[new Date(item.dueDate).getMonth()] +
                                                                ' ' +
                                                                new Date(item.dueDate).getDate() +
                                                                ', ' +
                                                                new Date(item.dueDate).getFullYear()}
                                                        </span>
                                                        <span>
                                                            {new Date(item.dueDate).toLocaleTimeString()}
                                                        </span>
                                                    </>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </td>
                                        <td style={{
                                                borderBottom:
                                                     item.statusIndicator === 'Expiring soon'
                                                        ? '1px solid #c02222'
                                                        : 'none',
                                            }}
                                            className="actions-column">
                                            {hoveredRow === index ? (
                                                <div className="action-main">
                                                    <div className="action-icons">
                                                        <BsInfoCircle
                                                            className="action-icon"
                                                            onClick={() =>
                                                                handleViewFunction(item.objectId, item.employeeId)
                                                            }
                                                        />
                                                        {isAdmin && isAdmin === '1' && (
                                                            <>
                                                                {item.status !== 'Reject' && (
                                                                    <FiEdit3
                                                                        className="action-icon"
                                                                        onClick={() => handleUpdate(item.objectId, item.employeeId)}
                                                                    />
                                                                )}
                                                                <BsTrash
                                                                    className="action-icon"
                                                                    onClick={() => handleDelete(item.objectId)}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="action-main">
                                                    <BsThreeDotsVertical
                                                        className="three"
                                                        onMouseEnter={() => handleMouseEnter(index)}
                                                        onMouseLeave={() => handleMouseLeave()}
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </Table>

                        {filterdDetails.length > itemsPerPage &&
                            <div className="pagination-container">
                                <div className="pagination">
                                    <ul className="pagination-lists">
                                        <li className="page-item-prev flex-center">
                                        {currentPage !== 1 ?
                                            (<a
                                            onClick={() => handlePageChange(currentPage - 1)}
                                            className="pagination-link"
                                            href="#"
                                            >
                                            <HiOutlineArrowSmLeft className='nxt-prev-icon' />
                                            </a>) :
                                            (<HiOutlineArrowSmLeft className='nxt-prev-icon' />)
                                        }
                                        </li>
                                        {Array.from({ length: Math.min(rowCount, 5) }, (_, index) => {
                                        const page = currentPage - 2 + index;
                                        return page > 0 && page <= Math.ceil(rowCount / itemsPerPage) ?
                                            (<li
                                            key={index}
                                            className={`page-item ${currentPage === page ? 'active' : ''}`}
                                            >
                                            <a
                                                onClick={() => handlePageChange(page)}
                                                className="page-link"
                                                href="#"
                                            >{page}</a>
                                            </li>) : null;
                                        })}
                                        <li className="page-item-nxt flex-center">
                                        {currentPage < Math.ceil(rowCount / itemsPerPage) ?
                                            (<a
                                            onClick={() => handlePageChange(currentPage + 1)}
                                            className="pagination-link"
                                            href="#"
                                            ><p className='nxt-button'><HiOutlineArrowSmRight className='nxt-prev-icon' /></p>
                                            </a>) :
                                            (<HiOutlineArrowSmRight className='nxt-prev-icon' />)
                                        }
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
                        }
                        
                    </div>
                ) : (
                    <div className='no-data-section'>
                        <div className='no-data-image-section'>
                        <img  src="/images/flat-design-no-data-illustration.png" alt="Profile Pic" className='no-data-image'/>
                        </div>

                        <div className='no-data-text-section'>
                        <p>No Records Found</p>
                        <span>There are no devices found. Please wait for new entries.</span>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default AllDevices;
