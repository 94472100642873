import React, { useEffect, useState } from 'react'
import { useParams,useNavigate } from 'react-router'
import baseUrl, { corporateUrl } from '../../config/config'
import "../../styles/deviceDetails/deviceDetails.css"
import Navbar from '../../components/navbar/Nabvar'
import api from '../../api/api'
import { FaRegUser } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { BsCalendar2Date } from "react-icons/bs";
import { ImMobile } from "react-icons/im";
import { FaCopy } from "react-icons/fa6";
import { IoCallOutline } from "react-icons/io5";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icons from '../../type/type';
import { Tooltip } from 'react-tooltip';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';


const DeviceDetails = () => {
    const token = localStorage.getItem('token');
    const isAdmin = localStorage.getItem("access_type");
    const { objId, empId } = useParams()
    const [employeeDetails, setEmployeeDetails] = useState(null)
    const [deviceDetails, setDeviceDetails] = useState(null);
    const [selectedDeviceType,setSelectedDeviceType] = useState(null)
    
    const navigate = useNavigate();

    useEffect(() => {
        fetchUserData()
        fetchDeviceData()
    }, [])

    const fetchUserData = async () => {
        try {
            const token = localStorage.getItem('token')
            console.log("token in details page test", token)
            if (!token) {
                console.warn("no token available")
            } else {
                const response = await api.get(`${corporateUrl}/api/user-details/${Number(empId)}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    },
                })
                console.log(response.data);
                setEmployeeDetails(response.data)
                console.log(employeeDetails);
            }
        } catch (err) {
            console.log(err)
        }
    };

    const getIconForDeviceType = (deviceType) => {
        const matchedIcon = icons.find(icon => icon.value === deviceType);
        setSelectedDeviceType( matchedIcon ? matchedIcon.label : null);
    };

    const copyToClipboard = (text) => {
        if (navigator.clipboard && window.isSecureContext) {
            // navigator.clipboard.writeText method for modern browsers
            navigator.clipboard.writeText(text).then(() => {
              alert(`${text} copied to clipboard!`);
            }).catch(err => {
              alert(`Failed to copy text: ${err}`);
            });
          } else {
            // document.execCommand('copy') method for older browsers
            const textElement = document.createElement('textarea');
            textElement.value = text;
            document.body.appendChild(textElement);
            textElement.select();
            try {
              document.execCommand('copy');
              alert(`${text} copied to clipboard!`);
            } catch (err) {
              alert(`Failed to copy text: ${err}`);
            }
            document.body.removeChild(textElement);
        }
    };

    const handleMailTo = (text) => {
        window.location.href = `mailto:${text}`;
    };

    const handleTel = (text) => {
        window.location.href = `tel:${text}`;
    };


    const handleDelete = async (objId) => {
        // Show a confirmation dialog
        const userConfirmed = window.confirm('Are you sure you want to delete this device entry?');

        if (userConfirmed) {
            try {
                if (!token) {
                    navigate("/login")
                } else {
                    await api.delete(`${baseUrl}/api/device-delete/${objId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                        },
                    });
                    navigate('/all-devices')
                }
            } catch (error) {
                console.error('Error deleting entry:', error);
                // setDeleteStatus('Error deleting entry');
            }
        } else {
            // setDeleteStatus('Deletion canceled');
        }
    };



    const formatDate = (date) =>{
        const registrationDate = new Date(date);
        const formattedDate = registrationDate.toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric', 
          });
          return formattedDate;
    }

    const handleUpdate = (objId, employee_id) => {
        navigate(`/update/${employee_id}/${objId}`)
    }

    const fetchDeviceData = async () => {
        try {
            const token = localStorage.getItem('token')
            if (!token) {
                console.log("no token available when calling device data")
            } else {
                const response = await api.get(`${baseUrl}/api/device-detail/${objId}`, {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                })
                setDeviceDetails(response.data);
                getIconForDeviceType(response.data.deviceType);
            }
        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        if (deviceDetails) {
          const dueDate = new Date(deviceDetails.dueDate);
          const today = new Date();
          
          let statusIndicator = '';
      
          if (dueDate) {
            const oneDayBeforeDueDate = new Date(dueDate);
            oneDayBeforeDueDate.setDate(dueDate.getDate() - 1);
      
            if (today >= oneDayBeforeDueDate && deviceDetails.status === 'Active') {
              // Show 'Expiring soon' if today is >= one day before due date and status is 'Active'
              statusIndicator = 'Expiring soon';
            } else if (dueDate && (deviceDetails.status === 'InActive' || deviceDetails.status === 'Reject')) {
              // Show 'Expired' if dueDate has a value and status is 'Inactive' or 'Rejected'
              statusIndicator = 'Expired';
            }
          }
      
          // Update the deviceDetails state with the statusIndicator
          setDeviceDetails({
            ...deviceDetails,
            statusIndicator: statusIndicator,
          });
        }
      }, [deviceDetails]);
    return (
        <div className="content-section">
            {employeeDetails && deviceDetails && (
              <div className='content_main_container'>
                <div className='back-button-sec'>   
                    <div data-tooltip-id="tool-tip-back-button" data-tooltip-content="Back" className='backBtn' onClick={() => navigate(-1)}><HiOutlineArrowSmLeft className='nxt-prev-icon' /></div>
                    <Tooltip id="tool-tip-back-button" className="back-tooltip" border="1px solid #102baa" place = "right"/>
                </div> 
                <div className="content-container">
                    <div className="employee-details">
                        <div className='details-head'>
                            <div className='user-section'>
                                <div className="profile-img">
                                    {employeeDetails.profile_pic === '' || !employeeDetails.profile_pic ? <img src="/images/dummy-profile.png" alt="profile-image" className='profile-image' /> : <img src={employeeDetails.profile_pic} alt="profile-image" className='profile-image'/>}
                                </div>
                                <div className='device-detail-user'>
                                    <span className='detail-user-name'>{employeeDetails.first_name+' '+ employeeDetails.last_name}</span>
                                    <span className='device-detail-sub-title'>{employeeDetails.role}</span>
                                </div>
                            </div>
                        </div>
                        <div className='device-all-details'>
                                
                            <div className='device_details_item'>
                                <span className='device_details_item_label'><FaRegUser /> ID</span>
                                <span className='device_details_item_value'>{employeeDetails.employeeId}</span>  
                            </div>

                            <div className='device_details_item device-hover-item'>
                                <span className='device_details_item_label'><MdOutlineEmail /> Email</span>
                                <span className='device_details_item_value'>
                                    {employeeDetails.email}
                                    <div className='device_details_item_after_hover'>
                                        <MdOutlineEmail  onClick={() => handleMailTo(employeeDetails.email)}/>
                                        <FaCopy onClick={() => copyToClipboard(employeeDetails.email)}/> 
                                    </div>
                                </span> 
                            </div>

                            <div className='device_details_item device-hover-item'>
                                
                                <span className='device_details_item_label'><ImMobile /> Phone</span>
                                <span className='device_details_item_value'>
                                    {employeeDetails.contact_no}
                                    <div className='device_details_item_after_hover'>  
                                        <IoCallOutline onClick={() => handleTel(employeeDetails.contact_no)}/>
                                        <FaCopy onClick={() => copyToClipboard(employeeDetails.contact_no)}/>
                                    </div>
                                </span>
                            </div>

                            <div className='device_details_item'>
                                <span className='device_details_item_label'><BsCalendar2Date /> Joining Date</span>
                                <span className='device_details_item_value'>{new Date(employeeDetails.joining_date).toLocaleDateString('en-US', {year: 'numeric',month: 'long',day: 'numeric',})}</span>  
                            </div>
                        </div>
                    </div>
                    <div className="device-details">
                        
                        <div className='device_details_heading'>
                            Device Overview
                        </div>
                        <div className='expireStatus'>
                            <div className='active_container' style={{ color: `${deviceDetails.status === "Active" ? "#008000" : deviceDetails.status === "InActive" ? "#102baa": deviceDetails.status ==="Pending" ? "#eea121" : "#FF0000"}`, backgroundColor: `${deviceDetails.status === "Active" ? "#e9faf1" : deviceDetails.status === "InActive" ? "#d7deff": deviceDetails.status ==="Pending" ? "#fcf3d7" : "#fee8e7"}`}}>
                                {deviceDetails.status}
                            </div>
                            <span className="bg-red">{deviceDetails.statusIndicator}</span>
                        </div>
                        <div className='device-all-details'>
                            <div className='device_details_item'>
                                <span className='device_details_item_label'>Device Type</span>
                                <span className='device_details_item_value'>{selectedDeviceType}</span>  
                            </div>

                            <div className='device_details_item'>
                                <span className='device_details_item_label'>Ticket Number</span>
                                <span className='device_details_item_value'>{deviceDetails.ticketNumber}</span>  
                            </div>


                            <div className='device_details_item'>
                                <span className='device_details_item_label'>Device Name</span>
                                <span className='device_details_item_value'>{deviceDetails.deviceName}</span>  
                            </div>

                            <div className='device_details_item'>
                                <span className='device_details_item_label'>Device Model</span>
                                <span className='device_details_item_value'>{deviceDetails.deviceModel}</span>  
                            </div>

                            <div className='device_details_item'>
                                <span className='device_details_item_label'>MAC Address</span>
                                <span className='device_details_item_value'>{deviceDetails.macAddress}</span>  
                            </div>

                            <div className='device_details_item'>
                                <span className='device_details_item_label'>Operating System</span>
                                <span className='device_details_item_value'>{deviceDetails.operatingSystem}</span>  
                            </div>

                            <div className='device_details_item'>
                                <span className='device_details_item_label'>Registration Date</span>
                                <span className='device_details_item_value'>{new Date(deviceDetails.registrationDate).toLocaleDateString('en-US', {year: 'numeric',month: 'long',day: 'numeric',})}</span>  
                            </div>

                            <div className='device_details_item'>
                                <span className='device_details_item_label'>Last Updated Date</span>
                                <span className='device_details_item_value'>{new Date(deviceDetails.lastUpdatedDate).toLocaleDateString('en-US', {year: 'numeric',month: 'long',day: 'numeric',})}</span>  
                            </div>

                            <div className='device_details_item'>
                                <span className='device_details_item_label'>Validity Period</span>
                                <span className='device_details_item_value'>{deviceDetails.validityPeriod+ ' '+ deviceDetails.validityUnit}</span>  
                            </div>

                            <div className='device_details_item'>
                                <span className='device_details_item_label'>Approval Date</span>
                                <span className='device_details_item_value'>{deviceDetails.approvalDate?new Date(deviceDetails.approvalDate).toLocaleDateString('en-US', {year: 'numeric',month: 'long',day: 'numeric',}): 'Not Approved'}</span>  
                            </div>

                            <div className='device_details_item'>
                                <span className='device_details_item_label'>Due Date</span>
                                <span className='device_details_item_value'>{new Date(deviceDetails.dueDate).toLocaleDateString('en-US', {year: 'numeric',month: 'long',day: 'numeric',})}</span>  
                            </div>
                        </div>
                    </div>
                    {isAdmin && isAdmin === 1 && (
                        <div className='device_details_action'>
                            <div className='device_details_delete' onClick={() => handleDelete(objId)}>Delete Device</div>
                            <div className='device_details_edit' onClick={() => handleUpdate(objId, empId)}>Edit Device</div>
                        </div>
                    )}
                </div>
            </div>
            )}
        </div>

    )
}

export default DeviceDetails