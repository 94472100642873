import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../../styles/styles.css'; // Import the CSS file
import Popup from '../../components/popup/Popup';
import { useNavigate, useParams } from 'react-router-dom';
import baseUrl from '../../config/config';
import Navbar from '../../components/navbar/Nabvar';
import api from '../../api/api';
import icons from '../../type/type';
import { Tooltip } from 'react-tooltip';
import { HiOutlineArrowSmLeft } from 'react-icons/hi';

const Update = () => {

  const { id, objId } = useParams();
  const [details, setDetails] = useState(null);
  const token = localStorage.getItem('token'); //retrive the token from the local storage
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    ticketNumber: '',
    deviceName: '',
    employeeId: Number(id),
    deviceModel: '',
    operatingSystem: '',
    status: '',
    macAddress: '',
    approvalDate:'',
    validityPeriod: '',
    validityUnit: '',
    dueDate: ''
  });
  const [popup, setPopup] = useState(null);
  const [deviceTypeId, setDeviceTypeId] = useState(null);
  const [showSnoozeButton, setShowSnoozeButton] = useState(false);


  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (!token) {
          navigate("/login")
        } else {
          const response = await api.get(`${baseUrl}/api/device-detail/${objId}`, {
            headers: {
              'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
            },
          });
          setDetails(response.data);
          setFormData(response.data);
          setDeviceTypeId(response.data.deviceType);
        }
      } catch (error) {
        console.error('Error fetching details:', error);
      }
    };

    fetchDetails();
  }, [id]);

  const calculateDueDate = (approvalDate, validityPeriod, validityUnit) => {
    const date = new Date(approvalDate);

    // Default to 3 months if validity period or unit is provided
    if (!validityPeriod || !validityUnit) {
      validityPeriod = 3;
      validityUnit = 'Month';
    }

    switch (validityUnit) {
      case 'Day':
        date.setDate(date.getDate() + validityPeriod);
        break;
      case 'Week':
        date.setDate(date.getDate() + validityPeriod * 7);
        break;
      case 'Month':
        date.setMonth(date.getMonth() + validityPeriod);
        break;
      default:
        break;
    }
    return date.toISOString().split('T')[0]; // Return date in YYYY-MM-DD format
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'status' && value === 'Active') {
      const approvalDate = new Date().toISOString();
      const dueDate = calculateDueDate(approvalDate, formData.validityPeriod, formData.validityUnit);
      setFormData({ ...formData, status: value, approvalDate, dueDate });
    } else if (name === 'status' && value === 'InActive' || value === 'Reject'){
      const dueDate = new Date();
      setFormData({ ...formData, [name]: value, status: value, dueDate });
    }  else {
      setFormData({ ...formData, [name]: value });
    }
  };
  
   // Check if today is one day before the due date
   const checkSnoozeVisibility = () => {
    const dueDate = new Date(formData.dueDate);
    const today = new Date();
    const oneDayBeforeDueDate = new Date(dueDate);
    oneDayBeforeDueDate.setDate(dueDate.getDate() - 1);
  
    // Show the snooze button if the status is 'Active' and today is one day before the due date, on the due date, or after the due date
    if (
      formData.status === 'Active' &&
      (today.toDateString() === oneDayBeforeDueDate.toDateString() ||
      today.toDateString() === dueDate.toDateString() ||
      today > dueDate)
    ) {
      setShowSnoozeButton(true);
    } else {
      setShowSnoozeButton(false);
    }
  };

  const handleSnooze = () => {
    const newDueDate = calculateDueDate(formData.dueDate, formData.validityPeriod, formData.validityUnit);
    setFormData({ ...formData, dueDate: newDueDate });
  };

  useEffect(() => {
    checkSnoozeVisibility();
  }, [formData.dueDate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Check if any input field is empty
    const requiredFields = [
      "ticketNumber",
      "deviceName",
      "deviceModel",
      "operatingSystem",
      "status",
      "macAddress"
    ];

    const emptyFields = requiredFields.filter(field => !formData[field] || (typeof formData[field] === 'string' && !formData[field].trim()));
    if (emptyFields.length > 0) {
      setPopup({ type: 'error', message: (emptyFields.length === 1 ? emptyFields+' field must be filled out': emptyFields +' fields must be filled out' )});
      return;
    }

    try {
      if (!token) {
        navigate("/login")
      } else {
        const res = await api.post(`${baseUrl}/api/device-update/${objId}`, formData, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        navigate(`/all-devices`);
      }
    } catch (error) {
      console.error('Error registering device:', error);
      if (error.response && error.response.data) {
        setPopup({ type: 'error', message: error.response.data });
      } else {
        setPopup({ type: 'error', message: 'Registration not completed due to some technical issues' });
      }
    }
  };

  const handleLoginClick = () => {
    navigate("/login")
  }

  const closePopup = () => {
    setPopup(null);
  };

  return (
    <div className='content-section-main'>
      <div className='back-button-sec'>   
        <div data-tooltip-id="tool-tip-back-button" data-tooltip-content="Back" className='backBtn' onClick={() => navigate(-1)}><HiOutlineArrowSmLeft className='nxt-prev-icon' /></div>
        <Tooltip id="tool-tip-back-button" className="back-tooltip" border="1px solid #102baa" place = "right"/>
      </div> 
      <div className='content-section-inner'>
        {
          details &&
          <form className="register-form" onSubmit={handleSubmit}>
            <div className='form-heading-main'>
              <div className='form-head'><h2>Edit Device</h2></div>
              {/* <div className='form-sub-head'><p>Please register your personal device here and wait for the approval</p></div> */}
            </div>
            <div className="form-row">
            <div className="form-group">
                <label htmlFor="deviceType">Device Type:</label>
                <select name="deviceType" onChange={handleChange} value={deviceTypeId}>
                  <option value="">Please select..</option>
                  {icons.map((option) => {
                      return (
                          <option key={option.value} value={option.value}>
                              {option.label}
                          </option>
                      );
                  })}
                </select>
              </div>
              
              <div className="form-group">
                <label htmlFor="deviceName">Device Name:</label>
                <input type="text" id="deviceName" name="deviceName" value={formData.deviceName} onChange={handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="deviceModel">Device Model:</label>
                <input type="text" id="deviceModel" name="deviceModel" value={formData.deviceModel} onChange={handleChange} />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="operatingSystem">Operating System:</label>
                <input type="text" id="operatingSystem" name="operatingSystem" value={formData.operatingSystem} onChange={handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="macAddress">Mac Address:</label>
                <input type="text" id="macAddress" name="macAddress" value={formData.macAddress} onChange={handleChange} />
              </div>

              <div className="form-group">
                <label htmlFor="ticketNumber">Ticket Number:</label>
                <input class='readonly-device-field' type="text" id="ticketNumber" name="ticketNumber" value={formData.ticketNumber} onChange={handleChange} readOnly/>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="macAddress">Validity Period:</label>
                <div className='validity-section'>
                  <input className="readonly-device-field" type="number" name="validityPeriod" value={formData.validityPeriod} readOnly/>
                  <input className="readonly-device-field" name="validityUnit" value={formData.validityUnit} readOnly/>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="status">Status:</label>
                <select name="status" id="status" onChange={handleChange} defaultValue={formData.status}>
                  {formData.status == "Pending" && <option value="Pending">Pending</option>}
                  <option value="Active">Active</option>
                  <option value="InActive">Inactive</option>
                  <option value="Reject">Reject</option>
                </select>
              </div>

              <div className="form-group">
                {formData.status === "InActive" && formData.dueDate || formData.status === "Reject" && formData.dueDate ?
                  <label htmlFor="dueDate">Expired Date:</label>
                : 
                  <label htmlFor="dueDate">Due Date:</label>
                }
                <div className='dueDate-section'>
                  {formData.dueDate ?
                    <input type="text" id="dueDate" name="dueDate" value={new Date(formData.dueDate).toLocaleDateString('en-US', {year: 'numeric',month: 'long',day: 'numeric',})} onChange={handleChange} readOnly/>
                  :
                    <input type="text" id="dueDate" name="dueDate" value={''} onChange={handleChange} readOnly/>
                  }
                  {showSnoozeButton && (
                    <button className='snooze-button' type="button" onClick={handleSnooze}>Snooze</button>
                  )}
                </div>
              </div>
            </div>
            
            <div className="form-row">
              <span className="bg-red">* All fields are requierd</span>
            </div>

            <div className='button-section'>
              <button className='button' type="submit">Save</button>
            </div>
          </form>
        }
        {popup && <Popup type={popup.type} message={popup.message} onClose={closePopup} />}
      </div>

    </div>

  );
};

export default Update;
