
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import '../../styles/statuspage/status.css'; // Import your CSS file for styling
import baseUrl, { corporateUrl } from '../../config/config';
import { Table } from 'react-bootstrap';
import "../../styles/table/table.css"
import { FaPlus } from "react-icons/fa";
import api from '../../api/api';
import { HiOutlineArrowSmLeft } from "react-icons/hi";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icons from '../../type/type';   
const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

const StatusPage = () => {
  const { id } = useParams();
  const [details, setDetails] = useState([]);
  const navigate = useNavigate()
  const token = localStorage.getItem('token'); //retrive the token from the local storage
  const [userDetails, setUserDetails] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [changed, setChanged] = useState(false);
  const [rowCount, setRowCount] = useState();
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const itemsPerPage = 4;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const displayedOrders = details?.slice(indexOfFirstItem, indexOfLastItem);
  var today = new Date();
  var currentTime = today.getHours()
  const role = localStorage.getItem("access_type")
  const fetchDetails = async () => {
    try {
      if(!token){
        navigate("/login")
      }else{
        const response = await api.get(`${baseUrl}/api/device-details/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        setDetails(response.data);
        setRowCount(response.data.length);


        const userResponse = await api.get(`${corporateUrl}/api/user-details/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        setUserDetails(userResponse.data);
      }
      
    } catch (error) {
      console.error('Error fetching details:', error);
    }
  };

  useEffect(() => {  
    localStorage.setItem("user_id", id)
    fetchDetails();
  }, [id]);

  const getIconForDeviceType = (deviceType) => {
    const matchedIcon = icons.find(icon => icon.value === deviceType);
    return matchedIcon ? matchedIcon.icon : null;
  };

  const handleStatusToggle = async (objId) => {
    try {
      if(!token){
        navigate("/login")
      }else{
        await api.post(`${baseUrl}/api/toggle-status/${objId}`, {
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          },
        });
        fetchDetails();
      }  
    } catch (error) {
      console.error('Error toggling user status:', error);
    }
  };
  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };


  const handleDelete = async (objId) => {
    // Show a confirmation dialog
    const userConfirmed = window.confirm('Are you sure you want to delete this device entry?');

    if (userConfirmed) {
        try {
          if(!token){
            navigate("/login")
          }else{
            await api.delete(`${baseUrl}/api/device-delete/${objId}`, {
              headers: {
                'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
              },
            });
            fetchDetails();
          }
        } catch (error) {
            console.error('Error deleting entry:', error);
            // setDeleteStatus('Error deleting entry');
        }
    } else {
        // setDeleteStatus('Deletion canceled');
    }
  };

  const handleUpdate = (objId) =>{
    navigate(`/update/${id}/${objId}`)
  }

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    console.log("test")
  }


  const BackToHome = () =>{
    navigate("/")
  }

  const registerDevice = () =>{
    navigate(`/register/${id}`)
  }
  return (
    <div className='content-section-status'>
      <div className='content-status-inner'>
        <div className='hello-head'>
        <div className='hello-text'>
            <span>Hello, {userDetails.first_name+ ' '+ userDetails.last_name} !</span>
          </div>
          <div className='wish-text'>
            {currentTime<12 ? <h5>Good Morning</h5>: currentTime< 18 ? <h5>Good Afternoon</h5>: <h5>Good Evening</h5>}
          </div>
        </div>
        <div className="emppl-head-section">
          <div className="table-heading">
            <h2>Enrolled Devices</h2>
          </div>
          <div className='flex-center'>
            <div className="add-empl-btn" onClick={registerDevice}>

            <FaPlus /> 
            </div>
            <div className='add-device-btn-label'>Add Device</div>
          </div>
          {/* Register Your laptop */}
        </div>
        {details.length > 0  ? (
          
          <div className="details-table">
            <div className='table-section'>
            <div className="table-container">

              <Table striped bordered hover className='custom-table'>
                    <thead className="custom-thead">
                      <tr className='custom-tr'>
                        <th className='name-hd'> DEVICE NAME</th>
                        <th style={{width: "15%"}}>DEVICE MODEL</th>
                        <th style={{width: "15%"}}>OPERATING SYSTEM</th>
                        <th style={{width: "15%"}}>MAC ADDRESS</th>
                        <th style={{width: "15%"}}>APPROVAL DATE</th>
                        <th style={{width: "5%"}}>STATUS</th>
                        {/* {role == "1" ?
                        <th className='action-head'>ACTIONS</th>
                      : null} */}
                      </tr>
                    </thead>
                    <tbody>
                      {displayedOrders.map((item, index) => 
                        <tr key={index}>
                          <td>
                            <div className="name-td">
                              <div className="device-type"><FontAwesomeIcon className="font-icons" icon={getIconForDeviceType(item.deviceType)}/> </div>
                              <div className="name-td-txt">{item.deviceName}</div>
                            </div>
                          </td>
                          {/* <td>{item.access_type == "1" ? "Admin" : "Employee"}</td> */}
                          <td>{item.deviceModel}</td>
                          <td>{item.operatingSystem}</td>
                          <td>{item.macAddress}</td>
                          <td>
                            <div className='date-section'>
                              {item.approvalDate?
                                <>
                                  <span>{month[new Date(item.approvalDate).getMonth()] +' '+ new Date(item.approvalDate).getDate()+', '+ new Date(item.approvalDate).getFullYear()}</span>
                                  <span>{new Date(item.approvalDate).toLocaleTimeString()}</span>
                              </>
                              : ''
                              }
                            </div>
                          </td>
                          <td>
                            <div className='active_container' style={{ color: `${item.status === "Active" ? "#008000" : item.status === "InActive" ? "#102baa": item.status ==="Pending" ? "#eea121" : "#FF0000"}`, backgroundColor: `${item.status === "Active" ? "#e9faf1" : item.status === "InActive" ? "#d7deff": item.status ==="Pending" ? "#fcf3d7" : "#fee8e7"}`}}>
                                {item.status}
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {details.length > itemsPerPage &&
                      <div className="pagination-container">
                          <div className="pagination">
                              <ul className="pagination-lists">
                                  <li className="page-item-prev flex-center">
                                  {currentPage !== 1 ?
                                      (<a
                                      onClick={() => handlePageChange(currentPage - 1)}
                                      className="pagination-link"
                                      href="#"
                                      >
                                      <HiOutlineArrowSmLeft className='nxt-prev-icon' />
                                      </a>) :
                                      (<HiOutlineArrowSmLeft className='nxt-prev-icon' />)
                                  }
                                  </li>
                                  {Array.from({ length: Math.min(rowCount, 5) }, (_, index) => {
                                  const page = currentPage - 2 + index;
                                  return page > 0 && page <= Math.ceil(rowCount / itemsPerPage) ?
                                      (<li
                                      key={index}
                                      className={`page-item ${currentPage === page ? 'active' : ''}`}
                                      >
                                      <a
                                          onClick={() => handlePageChange(page)}
                                          className="page-link"
                                          href="#"
                                      >{page}</a>
                                      </li>) : null;
                                  })}
                                  <li className="page-item-nxt flex-center">
                                  {currentPage < Math.ceil(rowCount / itemsPerPage) ?
                                      (<a
                                      onClick={() => handlePageChange(currentPage + 1)}
                                      className="pagination-link"
                                      href="#"
                                      ><p className='nxt-button'><HiOutlineArrowSmRight className='nxt-prev-icon' /></p>
                                      </a>) :
                                      (<HiOutlineArrowSmRight className='nxt-prev-icon' />)
                                  }
                                  </li>
                              </ul>
                          </div>
                      </div>
                    }
              </div>
            </div>
            
          </div>
        ) : (
          <div className='no-data-section'>
            <div className='no-data-image-section'>
              <img  src="/images/9169206.jpg" alt="Profile Pic" className='no-data-image'/>
            </div>

            <div className='no-data-text-section'>
              <p>No Records Found</p>
              <span>Register Your Personal Device and Enjoy Seamless Connectivity</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatusPage;
