// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Link, useNavigate, useParams } from 'react-router-dom';
// import '../../styles/sidebar/sidebar.css';
// import { corporateUrl } from '../../config/config';
// import baseUrl from '../../config/config';

// const Sidebar = () => {
//     //const navigate = useNavigate();
//     const [sidebarVisible, setSidebarVisible] = useState(false);
//     const [userDetails, setDetails] = useState(null);
//     const [isOpen, setIsOpen] = useState(false);
//     const [isExpanded, setIsExpanded] = useState(true);
//     const [id, setId] = useState("")

//     const fetchDetails = async () => {   

//         try {
//             const token = localStorage.getItem('token'); //retrive the token from the local storage
//             console.log("trying to get user details");
//             const response = await axios.get(`${corporateUrl}/api/user-details/${id}`, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
//                 },
//             });
//             setDetails(response.data);
//             console.log('UserD',response.data);
//             localStorage.setItem("access_type",response.data.access_type);
//         } catch (error) {
//             console.error('Error fetching details:', error);
//         }
//     };

//     useEffect(() => {
//         setTimeout(() => {
//             setId(localStorage.getItem('user_id'))
//         }, 1000)
//         fetchDetails();

//     }, [id]); 


//     const toggleSidebar = () => {
//         setSidebarVisible(!sidebarVisible);
//         setIsOpen(!isOpen);
//     };

//     const handleLogout = async () => {
//         try {
//             // Remove token and user_id from localStorage
//             localStorage.removeItem('token');
//             localStorage.removeItem('user_id');
//             window.close()
//             //const response = await axios.post(`${baseUrl}/api/logout`, { id: id });
//         } catch (error) {
//             console.error('Error logging out:', error);
//         }
//     };

//     const toggleExpand = () => {
//         setIsExpanded(!isExpanded);
//     };
//     return (
//         <div className="sid_nav_main">
//                         {(
//                 <button className="expand_button" onClick={toggleExpand}>
//                     {!isExpanded ? `☰` : `X`}
//                 </button>
//             )}
//         <div className={`sidebar visible ${isExpanded ? 'expanded' : 'collapsed'}`}>
//             <Link to={`/home/${id}`}>  
//                 <img src='/images/polus.webp' alt="Logo" className="logo" />
//             </Link>
//             {userDetails && (
                
//                     <div className="user-profile">

//                         <div className="user-info">
//                             <span className="user-email">Hello , {userDetails.first_name}</span>
//                         </div>
//                     </div>
                
//             )}
//             <Link to={`/status/${id}`}>
//                 <img src='/images/hom.png' alt="Home Icon" className="sidebar-icon" />
//                 <span className='menu-name'>Home</span>
//             </Link>
//             <Link to={`/register/${id}`}>
//                 <img src='/images/add.png' alt="Home Icon" className="sidebar-icon" />
//                 <span className='menu-name'>Add Device</span>
                
//             </Link>
//             {userDetails && (userDetails.access_type === 1 || userDetails.access_type === 3) && (
//                 <Link to={`/all-devices`}>
//                     <img src='/images/users.png' alt="Home Icon" className="sidebar-icon" />
//                     <span className='menu-name'>All Devices</span>
//                 </Link>
//             )}
//             <div className="logout">
//                 <Link to="/login" onClick={handleLogout}>
//                     <img src='/images/logout.png' alt="Home Icon" className="sidebar-icon" />
//                     <span className='menu-name'>Logout</span>
//                 </Link>
//             </div>
//         </div>
//         </div>
//     )
// };

// export default Sidebar;
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams,useLocation } from 'react-router-dom';
import '../../styles/sidebar/sidebar.css';
import { corporateUrl } from '../../config/config';
import baseUrl from '../../config/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FaLaptopHouse } from "react-icons/fa";
import { MdDevices } from "react-icons/md";
import { IoAddCircleSharp } from "react-icons/io5";
import { Tooltip } from 'react-tooltip';
import api from '../../api/api';

const Sidebar = ({activeIndex, setActiveIndex}) => {
    // const navigate = useNavigate();
    const location = useLocation();
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [userDetails, setDetails] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const [id, setId] = useState("");
    // const [activeIndex,setActiveIndex] = useState(1);
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const fetchDetails = async () => {

        try {
            const token = localStorage.getItem('token'); //retrive the token from the local storage
            const response = await api.get(`${corporateUrl}/api/user-details/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
                },
            });
            setDetails(response.data);
            console.log(response.data)
        } catch (error) {
            console.error('Error fetching details:', error);
        }
    };

    const navigate = (id) => {
        setActiveIndex(id);
    }

    useEffect(() => {
        setTimeout(() => {
            setId(localStorage.getItem('user_id'))
        }, 1000)

        fetchDetails();

    }, [id]);


    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
        setIsOpen(!isOpen);
    };

    const handleLogout = async () => {
        try {
            // Remove token and user_id from localStorage
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            localStorage.removeItem('authenticated')
            window.close()
            const response = await api.post(`${baseUrl}/api/logout`, { id: id });
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };

    return (
        <div className="sid_nav_main">
            {(
            <button className="expand_button" onClick={toggleExpand}>
                {!isExpanded ? `☰` : ` X`}
            </button>
            )}
            <div className={`sidebar visible ${isExpanded ? 'expanded' : 'collapsed'}`}>

                <div className="sidebar visible">
                    <div>
                        <Link to={`/status/${id}`} >
                            {/* <img src='/images/hom.png' alt="Home Icon" className="sidebar-icon" /> */}
                            <FaLaptopHouse data-tooltip-id="tool-tip-nav1" data-tooltip-content="Home" className={location.pathname == `/status/${id}`? "font-icons  menu-icon-cutom active" : "font-icons  menu-icon-cutom"} onClick={() => navigate(1)}/>
                            <Tooltip id="tool-tip-nav1" className="nav-tooltip" border="1px solid #102baa"/>
                        </Link>
                        
                        <Link to={`/register/${id}`} >
                            <IoAddCircleSharp data-tooltip-id="tool-tip-nav2" data-tooltip-content="Add Device" className={location.pathname ==`/register/${id}` ? "font-icons menu-icon-cutom active" : "font-icons menu-icon-cutom"} onClick={() => navigate(2)}/>
                            <Tooltip id="tool-tip-nav2" className="nav-tooltip"border="1px solid #102baa" />
                        </Link>
                        {userDetails && (userDetails.access_type === 1 || userDetails.access_type === 3) && (
                            <>
                                <Link to={`/all-devices`} >
                                    <MdDevices data-tooltip-id="tool-tip-nav3" data-tooltip-content="All Devices" className={location.pathname ==`/all-devices` ? "font-icons menu-icon-cutom active" : "font-icons menu-icon-cutom"} onClick={() => navigate(3)}/>
                                    <Tooltip id="tool-tip-nav3" className="nav-tooltip" border="1px solid #102baa"/>
                                </Link>
                            </>
                        )}
                            
                    </div>
                    <div className="logout">
                        <Link to="/login" onClick={handleLogout}>
                            <FontAwesomeIcon data-tooltip-id="tool-tip-nav-logout" data-tooltip-content="Logout" className='font-icons fa-light' icon={faRightFromBracket} />
                            <Tooltip id="tool-tip-nav-logout" className="nav-tooltip" border="1px solid #102baa"/>
                        </Link>
                    </div>
                    <div>

                    </div>
                </div>
            </div>
        </div>
    )
};

export default Sidebar;

